<template>
  <section class="invoice-preview-wrapper">
    <b-row class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <Logo />
                </div>

                <!-- <p class="card-text mb-25">
                  {{ this.officeAddress }}
                </p> -->

                <div v-html="this.officeAddress"></div>

                <p class="card-text mb-0">Phone: {{ this.officeEmail }}</p>
                <p class="card-text mb-0">Email: {{ this.officeMobile }}</p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Invoice
                  <span class="invoice-number"
                    ># {{ milestoneInfo?.invoice_no }}</span
                  >
                  <template v-if="milestoneInfo?.status == 'paid'">
                    <b-badge pill variant="light-success" class="ml-1"
                      >Paid</b-badge
                    >
                  </template>
                  <template v-else-if="milestoneInfo?.status == 'due'">
                    <b-badge pill variant="light-danger" class="ml-1"
                      >Due</b-badge
                    >
                  </template>
                  <template v-else-if="milestoneInfo?.status == 'forward'">
                    <b-badge pill variant="light-warning" class="ml-1"
                      >All Forwarded</b-badge
                    >
                  </template>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">Date Issued:</p>
                  <p class="invoice-date">
                    {{ formatDeadline(milestoneInfo?.invoice_created_date) }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">Due Date:</p>
                  <p class="invoice-date">
                    {{ formatDeadline(milestoneInfo?.date) }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Invoice Client & Payment Details -->
          <b-card-body class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <!-- Col: Invoice To -->
              <b-col cols="12" xl="6" class="p-0">
                <h6 class="mb-2">Invoice To:</h6>
                <h6 class="mb-25">
                  {{
                    milestoneInfo?.project?.data?.lead?.data?.key_contact_person
                  }}
                </h6>
                <p class="card-text mb-25">
                  {{ milestoneInfo?.project?.data?.lead?.data?.company_name }}
                </p>
                <p class="card-text mb-25">
                  Address:
                  {{ milestoneInfo?.project?.data?.lead?.data?.address }}
                </p>
                <p class="card-text mb-25">
                  Email:
                  {{ milestoneInfo?.project?.data?.lead?.data?.email }}
                </p>
                <p class="card-text mb-25">
                  Mobile:
                  {{ milestoneInfo?.project?.data?.lead?.data?.mobile }}
                </p>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2">Payment Details:</h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">Total Due:</td>
                        <td>
                          <span class="font-weight-bold">{{
                            milestoneInfo?.grand_amount
                          }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">Bank Name:</td>
                        <td>{{ accountInfo?.bank_name }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">Account Name:</td>
                        <td>{{ accountInfo?.name }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">Account Number:</td>
                        <td>{{ accountInfo?.account_number }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">Routing Number:</td>
                        <td>{{ accountInfo?.routing_number }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">Country:</td>
                        <td>{{ accountInfo?.country?.data?.name }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">IBAN:</td>
                        <td>
                          {{
                            accountInfo?.iban_number
                              ? accountInfo?.iban_number
                              : "N/A"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">SWIFT code:</td>
                        <td>
                          {{
                            accountInfo?.swift_code
                              ? accountInfo?.swift_code
                              : "N/A"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <b-table-lite
            responsive
            :items="invoiceDescription"
            :fields="['miltestoneTitle', 'type', 'amount', 'vat', 'tax']"
          >
            <template #cell(miltestoneTitle)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.miltestoneTitle }}
              </b-card-text>
            </template>
            <template #cell(amount)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ `${milestoneInfo?.currency} ${data.item.amount}` }}
              </b-card-text>
            </template>
          </b-table-lite>
          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <!-- <b-card-text class="mb-0">
                  <span class="font-weight-bold">Salesperson:</span>
                  <span class="ml-75">Alfie Solomons</span>
                </b-card-text> -->
              </b-col>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Subtotal:</p>
                    <p class="invoice-total-amount">
                      {{ milestoneInfo?.total_amount }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">VAT:</p>
                    <p class="invoice-total-amount">
                      {{ milestoneInfo?.total_vat_amount }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">TAX:</p>
                    <p class="invoice-total-amount">
                      {{ milestoneInfo?.total_tax_amount }}
                    </p>
                  </div>
                  <hr class="my-50" />
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Grand Total:</p>
                    <p class="invoice-total-amount">
                      {{ milestoneInfo?.grand_amount }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <span
              >It was a pleasure working with you and your team. We hope you
              will keep us in mind for future freelance projects. Thank
              You!</span
            >
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <b-card>
          <!-- Button: Send Invoice -->
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-invoice
            variant="primary"
            class="mb-75"
            block
          >
            Send Invoice
          </b-button> -->

          <!-- Button: DOwnload -->
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
          >
            Download
          </b-button> -->

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            v-on:click="printInvoice"
          >
            Print
          </b-button>

          <!-- Button: Edit -->

          <!-- Button: Add Payment -->
          <template
            v-if="$permissionAbility(INVOICE_PAYMENT_ACCESS, permissions)"
          >
            <b-button
              v-b-toggle.sidebar-invoice-add-payment
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mb-75"
              block
              :disabled="milestoneInfo?.status == 'due' ? false : true"
            >
              Collect Payment
            </b-button>
          </template>
        </b-card>
      </b-col>
    </b-row>

    <!-- Printed Invoice  -->
    <b-row class="invoice-preview" id="content" hidden>
      <b-col cols="12" xl="12" md="12">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <logo />
                </div>
                <p class="card-text mb-25">
                  {{ this.officeAddress }}
                </p>

                <p class="card-text mb-0">{{ this.officeEmail }}</p>
                <p class="card-text mb-0">{{ this.officeMobile }}</p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2 mr-5">
                <h4 class="invoice-title">
                  Invoice
                  <span class="invoice-number"
                    ># {{ milestoneInfo?.invoice_no }}</span
                  >
                  <template v-if="milestoneInfo?.status == 'paid'">
                    <b-badge
                      style="
                        background-color: rgba(40, 199, 111, 0.12);
                        color: #008000;
                      "
                      class="ml-1"
                      >Paid</b-badge
                    >
                  </template>
                  <template v-else-if="milestoneInfo?.status == 'due'">
                    <b-badge
                      style="
                        background-color: rgba(234, 84, 85, 0.12);
                        color: #ff0000;
                      "
                      class="ml-1"
                      >Due</b-badge
                    >
                  </template>
                  <template v-else-if="milestoneInfo?.status == 'forward'">
                    <b-badge pill variant="light-warning" class="ml-1"
                      >All Forwarded</b-badge
                    >
                  </template>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Date Issued:
                    <b>{{
                      formatDeadline(milestoneInfo?.invoice_created_date)
                    }}</b>
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Due Date:
                    <b>
                      {{ formatDeadline(milestoneInfo?.date) }}
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Invoice Client & Payment Details -->
          <b-card-body class="invoice-padding pt-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Invoice Client: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <!-- <logo /> -->
                  <h6 class="mb-2">Invoice To:</h6>
                </div>
                <h6 class="mb-25">
                  {{
                    milestoneInfo?.project?.data?.lead?.data?.key_contact_person
                  }}
                </h6>
                <p class="card-text mb-25">
                  {{ milestoneInfo?.project?.data?.lead?.data?.company_name }}
                </p>
                <p class="card-text mb-25">
                  Address:
                  {{ milestoneInfo?.project?.data?.lead?.data?.address }}
                </p>
                <p class="card-text mb-25">
                  Email:
                  {{ milestoneInfo?.project?.data?.lead?.data?.email }}
                </p>
                <p class="card-text mb-25">
                  Mobile:
                  {{ milestoneInfo?.project?.data?.lead?.data?.mobile }}
                </p>
              </div>

              <!-- Payment Details: Right Content -->
              <div class="mt-md-0 mt-2 mr-5">
                <h6 class="mb-2">Payment Details:</h6>

                <table>
                  <tbody>
                    <tr>
                      <td class="pr-1">Total Due:</td>
                      <td>
                        <span class="font-weight-bold">{{
                          milestoneInfo?.grand_amount
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">Bank Name:</td>
                      <td>{{ accountInfo?.bank_name }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">Account Name:</td>
                      <td>{{ accountInfo?.name }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">Account Number:</td>
                      <td>{{ accountInfo?.account_number }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">Routing Number:</td>
                      <td>{{ accountInfo?.routing_number }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">Country:</td>
                      <td>{{ accountInfo?.country?.data?.name }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">IBAN:</td>
                      <td>
                        {{
                          accountInfo?.iban_number
                            ? accountInfo?.iban_number
                            : "N/A"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">SWIFT code:</td>
                      <td>
                        {{
                          accountInfo?.swift_code
                            ? accountInfo?.swift_code
                            : "N/A"
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-card-body>

          <!-- <b-table-lite
            responsive
            :items="invoiceDescription"
            :fields="['miltestoneTitle', 'type', 'amount', 'vat', 'tax']"
          >
            <template #cell(miltestoneTitle)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.miltestoneTitle }}
              </b-card-text>
            </template>
          </b-table-lite> -->
          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <!-- <b-card-text class="mb-0">
                  <span class="font-weight-bold">Salesperson:</span>
                  <span class="ml-75">Alfie Solomons</span>
                </b-card-text> -->
              </b-col>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end pr-5"
                order="1"
                order-md="2"
              >
                <div class="mr-5">
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1 invoice-total-title">Subtotal:</td>
                        <td>
                          <span style="font-weight: 600">{{
                            milestoneInfo?.total_amount
                          }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">VAT:</td>
                        <td>
                          <span style="font-weight: 600">{{
                            milestoneInfo?.total_vat_amount
                          }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">TAX:</td>
                        <td>
                          <span style="font-weight: 600">{{
                            milestoneInfo?.total_tax_amount
                          }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2"><hr class="my-50" /></td>
                      </tr>

                      <tr>
                        <td class="pr-1">Grand Total:</td>
                        <td>
                          <span style="font-weight: 600">{{
                            milestoneInfo?.grand_amount
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <span
              >It was a pleasure working with you and your team. We hope you
              will keep us in mind for future freelance projects. Thank
              You!</span
            >
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <invoice-sidebar-send-invoice />
    <template v-if="milestoneInfo">
      <invoice-sidebar-add-payment
        :milestoneInfo="milestoneInfo"
        v-on:loadMilestoneInfo="updateMilestoneInfo"
      />
    </template>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { INVOICE_PAYMENT_ACCESS } from "@/helpers/permissionsConstant";

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BBadge,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
// import invoiceStoreModule from "../invoiceStoreModule";
import InvoiceSidebarSendInvoice from "./InvoiceSidebarSendInvoice.vue";
import InvoiceSidebarAddPayment from "./InvoiceSidebarAddPayment.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, email, integer, between } from "vee-validate/dist/rules";
export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },

  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BBadge,

    Logo,
    InvoiceSidebarAddPayment,
    InvoiceSidebarSendInvoice,
  },

  data() {
    return {
      INVOICE_PAYMENT_ACCESS,

      invoiceDescription: [],
      milestoneInfo: "",
      officeAddress: "",
      officeEmail: "",
      officeMobile: "",
      accountInfo: "",
    };
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },
  async created() {
    this.loadItems();
  },

  methods: {
    lazyLoad(fn, ms) {
      return new Promise((resolve) => setTimeout(() => resolve(fn(), ms)));
    },
    windowPrint() {
      window.print();
    },
    async printInvoice() {
      let base = document.body.innerHTML;
      let content = document.getElementById("content").innerHTML;

      document.body.innerHTML = content;

      await this.lazyLoad(this.windowPrint, 500);
      document.body.innerHTML = base;
    },
    async getSetting(params) {
      return await this.$api.get("/api/settings", {
        params: {
          select: params.select,
        },
      });
    },
    async getActiveAccountInfo() {
      return await this.$api.get("api/accounts/active/all?include=country");
    },
    async loadItems() {
      try {
        this.invoiceDescription = [];
        const [milestoneInfo, getSetting, accounts] = await Promise.all([
          this.getMilestoneInfo(),
          this.getSetting({
            select: "account_id,address,email,mobile",
          }),
          this.getActiveAccountInfo(),
        ]);

        this.milestoneInfo = milestoneInfo?.data?.data;

        this.officeAddress = getSetting?.data?.data?.address;
        this.officeEmail = getSetting?.data?.data?.email;
        this.officeMobile = getSetting?.data?.data?.mobile;

        if (this.milestoneInfo?.account) {
          this.accountInfo = this.milestoneInfo?.account?.data;
        } else {
          const defaultAccId = getSetting?.data?.data?.account_id;
          this.accountInfo = (accounts?.data?.data || []).find((item) => {
            if (item?.id == defaultAccId) {
              return item;
            }
          });
        }

        const item = {
          miltestoneTitle: this.milestoneInfo?.title,
          type: "Main",
          amount: this.milestoneInfo?.amount,
          vat: this.milestoneInfo?.vat_amount,
          tax: this.milestoneInfo?.tax_amount,
        };
        this.invoiceDescription.push(item);
        const forwardedMilestones = (
          this.milestoneInfo?.milestoneForwardFromHistories?.data || []
        ).map((item) => {
          if (item?.forward_status == "forward_all") {
            return {
              miltestoneTitle: item?.forwardFrom?.data?.title,
              type: "Forwarded All",
              amount: item?.forwardFrom?.data?.amount,
              vat: item?.forwardFrom?.data?.vat_amount,
              tax: item?.forwardFrom?.data?.tax_amount,
            };
          } else if (item?.forward_status == "forward_vat") {
            return {
              miltestoneTitle: item?.forwardFrom?.data?.title,
              type: "Forwarded VAT",
              amount: "N/A",
              vat: item?.forwardFrom?.data?.vat_amount,
              tax: "N/A",
            };
          } else if (item?.forward_status == "forward_tax") {
            return {
              miltestoneTitle: item?.forwardFrom?.data?.title,
              type: "Forwarded TAX",
              amount: "N/A",
              vat: "N/A",
              tax: item?.forwardFrom?.data?.tax_amount,
            };
          }
        });

        this.invoiceDescription.push(...forwardedMilestones);
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    updateMilestoneInfo() {
      this.loadItems();
    },
    statusVariant(value) {
      if (value) {
        return "light-success";
      }
      return "light-danger";
    },
    async getMilestoneInfo() {
      const id = this.$route.params.id;
      return await this.$api.get(
        `api/milestones/${id}?include=account.country,project.lead,milestoneForwardFromHistories.forwardFrom,milestoneForwardFromHistories.forwardTo,milestoneForwardToHistories.forwardFrom,milestoneForwardToHistories.forwardTo`
      );
    },
    formatDeadline(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
